<script setup lang="ts">
import { SEGMENT_TRACK_EVENTS, SHARE_SOCIAL_LINK } from "~/constants";

export interface BaseSocialIconsProps {
  /**
   * The URL for the Facebook link.
   */
  facebookUrl?: string;
  /**
   * The URL for the Youtube link.
   */
  youtubeUrl?: string;

  /**
   * The URL for the X link.
   */
  xUrl?: string;

  /**
   * The URL for the Instagram link.
   */
  instagramUrl?: string;
  /**
   * The URL for the TikTok link.
   */
  tikTokUrl?: string;

  /**
   * The URL for the website link.
   */
  webUrl?: string;

  /**
   * The URL for the mail link.
   */
  mailUrl?: string;

  /**
   * The URL for the Google Play link.
   */
  googlePlayUrl?: string;

  /**
   * The URL for the App Store link.
   */
  appStoreUrl?: string;
}

const segment = useSegment();
const sourceLocation = useSourceLocation();
const props = defineProps<BaseSocialIconsProps>();

function onSocialClick(method: TSocialMediaPlatform, url: string) {
  return segment.track(SEGMENT_TRACK_EVENTS.CLICK_ON_SOCIAL_MEDIA_LINK, { method, url, source_location: sourceLocation.value });
}
</script>

<template>
  <div class="flex justify-center gap-8">
    <BaseButton
      v-if="props.facebookUrl"
      :href="props.facebookUrl"
      tag="a"
      type="icon"
      name="share-facebook"
      target="_blank"
      @click="onSocialClick(SHARE_SOCIAL_LINK.FACEBOOK, props.facebookUrl)"
    >
      <IconSocialFacebook />
    </BaseButton>
    <BaseButton
      v-if="props.xUrl"
      :href="props.xUrl"
      tag="a"
      type="icon"
      name="share-x"
      target="_blank"
      @click="onSocialClick(SHARE_SOCIAL_LINK.X, props.xUrl)"
    >
      <IconSocialX />
    </BaseButton>
    <BaseButton
      v-if="props.instagramUrl"
      :href="props.instagramUrl"
      tag="a"
      type="icon"
      name="share-instagram"
      target="_blank"
      @click="onSocialClick(SHARE_SOCIAL_LINK.INSTAGRAM, props.instagramUrl)"
    >
      <IconSocialInstagram />
    </BaseButton>
    <BaseButton
      v-if="props.youtubeUrl"
      :href="props.youtubeUrl"
      tag="a"
      type="icon"
      name="share-youtube"
      target="_blank"
    >
      <IconSocialYoutube />
    </BaseButton>
    <BaseButton
      v-if="props.tikTokUrl"
      :href="props.tikTokUrl"
      tag="a"
      type="icon"
      name="share-tiktok"
      target="_blank"
    >
      <IconSocialTikTok />
    </BaseButton>
    <BaseButton
      v-if="props.webUrl"
      :href="props.webUrl"
      tag="a"
      type="icon"
      name="share-web"
      target="_blank"
      @click="onSocialClick(SHARE_SOCIAL_LINK.WEB, props.webUrl)"
    >
      <IconSocialWeb />
    </BaseButton>
    <a v-if="props.mailUrl" :href="`mailto:${props.mailUrl}`" aria-label="Mail Link">
      <IconSocialMail />
    </a>
  </div>
  <div v-if="props.googlePlayUrl || props.appStoreUrl" class="flex flex-wrap items-center justify-center gap-8">
    <a
      v-if="props.googlePlayUrl"
      :href="props.googlePlayUrl"
      target="_blank"
      aria-label="Google Play"
    >
      <IconSocialGooglePlay />
    </a>
    <a
      v-if="props.appStoreUrl"
      :href="props.appStoreUrl"
      target="_blank"
      aria-label="App Store"
    >
      <IconSocialAppStore />
    </a>
  </div>
</template>
